amplify-authenticator {
  --container-height: 0px;
  --amplify-primary-contrast: #000;
  --footer-color: #000;
}

.social-button {
  margin-bottom: 15px;
}

.card {
  margin-top: 30px;
}

.slim-line {
  border-color: #333333;
}

.left-block-title {
  margin-bottom: 30px;
}

.left-block-text {
  font-size: 20px;
}

.or-block{
  padding: 20px 0 60px;
}

.card-title {
  margin-bottom: 30px;
}

.alignleft {
  float: left;
  width:33.33333%;
  text-align:left;
}

.aligncenter {
  float: left;
  width:33.33333%;
  text-align:center;
}

.alignright {
 float:right;
 width: 33.33333%;
 text-align: right;
}
