.first-letter-toolbar {
  display: inline-block;
  padding: 40px;
  text-align: center;
  max-width: 566px;
  background: #FAFAFA;
  box-shadow: 3px 5px 4px rgba(201, 201, 201, 0.35);
}

.first-letter-toolbar-header {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 21px;
  margin-top: 15px;
  margin-bottom: 45px;
}

.letter-box {
  display: inline-block;
  margin: 10px 7px 10px 7px;
  width: 40px;
  height: 40px;
  box-shadow: 0px 2px 3px rgba(201, 201, 201, 0.35);
  background: #F4F4F4;
}

.letter-box:hover {
  text-decoration: none;
}

.letter {
  color: #404040;
  font-size: 20px;
  line-height: 40px;
}
.selected{
  font-weight: bold;
}

.first-letter-toolbar a:visited {
  color: #404040;
}

@media (max-width: 991px) {
  .first-letter-toolbar {
    padding: 10px;
  }

  .letter-box {
    width: 30px;
    height: 30px;
    margin: 10px 5px 10px 5px;
  }
  
  .letter {
    font-size: 14px;
    line-height: 30px;
  }
}