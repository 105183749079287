.translation-selector {
  max-width: 400px;
  padding: 40px;
  background: #FAFAFA;
  box-shadow: 3px 5px 4px rgba(201, 201, 201, 0.35);
  display: inline-block;
  vertical-align: top;
  text-align: center;
}

.translation-selector-header {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 21px;
  margin-top: 15px;
  margin-bottom: 45px;
}